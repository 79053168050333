import { api } from '@api/index';
import { ParentProductRo } from '@api/ocb-digital/offer/types';
import { OrderType } from '@api/ocb-digital/order/types';
import { useAuth } from '@lib/auth/AuthContext';
import { getEnv } from '@lib/env/getEnv';
import { formatGenericError } from '@lib/fetch/errors';
import { generateSourceId } from '@lib/helpers/utils';
import { withQueryParams } from '@lib/route/queryParams';
import { useTenantProps } from '@lib/tenants/TenantPropsContext';
import { findAvailableParentProduct } from '@templates/purchase/common/helpers';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { Dispatch, SetStateAction } from 'react';
import { toast } from 'react-toastify';
import { Step } from './types';

interface Props {
  offerCategoryId?: string;
  products?: ParentProductRo[];
  setSelectedParentProduct?: Dispatch<SetStateAction<ParentProductRo | null>>;
  nextStepUrl: string;
  orderType: OrderType;
  referenceUrl?: string;
}

export const useParentProductSelection = ({
  offerCategoryId,
  products,
  setSelectedParentProduct,
  nextStepUrl,
  orderType,
  referenceUrl,
}: Props) => {
  const { tenant } = useTenantProps();
  const { isAuthenticated } = useAuth();
  const { t } = useTranslation();
  const router = useRouter();
  const { orderId: orderIdFromQuery } = router.query;
  const env = getEnv();

  const onProductSelect = async (offerId: string, productId: string) => {
    try {
      const sourceId = generateSourceId();
      const parentProduct = findAvailableParentProduct(
        products,
        offerId,
        productId,
      );
      if (!parentProduct) throw new Error(t('common:errors.unexpected'));
      setSelectedParentProduct && setSelectedParentProduct(parentProduct);
      const tenantId = tenant?.config.setup.id[env.client.appEnv];
      if (!tenantId) throw new Error(t('common:errors.tenantIdNotDefined'));
      const orderId = orderIdFromQuery
        ? orderIdFromQuery
        : await createNewOrder(tenantId, orderType);

      // eslint-disable-next-line
      const { uid, ...queryParams } = router.query;
      const step: Step = 'addons';

      router.push(
        withQueryParams(nextStepUrl, {
          ...queryParams,
          orderId,
          offerId,
          sourceId,
          productId,
          step,
          ...(referenceUrl && { referenceUrl }),
          ...(offerCategoryId && { offerCategoryId }),
        }),
        undefined,
        {
          shallow: true,
        },
      );
    } catch (error) {
      toast.error(formatGenericError(error));
    }
  };

  return {
    onProductSelect,
  };

  async function createNewOrder(
    tenantId: string,
    orderType: OrderType,
  ): Promise<string> {
    const order = isAuthenticated
      ? await api.ocbDigital.order.createNewOrder(tenant, orderType)
      : await api.ocbDigital.orderAuthless.createNewAuthlessOrder(
          tenant,
          tenantId,
        );

    return order.id;
  }
};
