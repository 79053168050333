import { FC } from 'react';

import { Typography } from '@mui/material';

interface Props {
  description?: string;
}

export const ProductDescription: FC<Props> = ({ description }) => {
  if (!description) return null;

  return (
    <Typography fontSize={14} fontWeight={400} lineHeight="16px">
      {description}
    </Typography>
  );
};
