import { FC } from 'react';
import { useTranslation } from 'next-i18next';

import { APP_ROUTES } from '@lib/route/constants';
import { withQueryParams } from '@lib/route/queryParams';
import { SxStyles } from '@lib/theme/sxTheme';
import { Button } from '@ui/buttons/Button';
import { LinkButton } from '@ui/buttons/LinkButton';

import { CtaProps } from '../types';

interface Props {
  ctaProps?: CtaProps;
  startIcon?: React.ReactNode;
  productId?: string;
  offerId?: string;
}

export const CtaButton: FC<Props> = ({
  offerId = '',
  ctaProps,
  startIcon,
  productId = '',
}) => {
  const { t } = useTranslation();

  if (ctaProps) {
    const { onClick, text } = ctaProps;

    return (
      <Button
        onClick={() => onClick(offerId, productId)}
        sx={styles.getValue('btnText')}
        fullWidth
      >
        {text}
      </Button>
    );
  }

  return (
    <LinkButton
      startIcon={startIcon}
      sx={styles.getValue('btn')}
      href={withQueryParams(APP_ROUTES.PLANS.path, { offerId })}
      variant="contained"
    >
      {t('common:prepaidPackages.add')}
    </LinkButton>
  );
};

const styles = new SxStyles({
  btn: {
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
    textAlign: 'center',
    borderRadius: '20px',
    px: 2,
    py: 1,
    backgroundColor: 'primary.main',
  },
  btnText: {
    color: '#fff',
  },
});
