import { ParentProductRo } from '@api/ocb-digital/offer/types';
import { ProductGroup, SelectedProduct } from '@api/ocb-digital/order/types';
import { SimValidateRo } from '@api/ocb-digital/sim/types';
import { AvailableProduct } from '@api/prismic/components/types';
import { ParentProduct, Product } from '@lib/offers/types';

import { Order } from '@lib/payment/types';
import { PurchaseQueryParams } from './types';
import { NextRouter } from 'next/router';
import { PhoneNumberFormValues } from './phone-number-step/types';

export const findAvailableParentProduct = (
  parentProducts: ParentProductRo[] | undefined,
  offerId: string | undefined,
  productId: string | undefined,
): ParentProductRo | undefined => {
  return parentProducts?.find(
    (item) =>
      item.blob.offerId === offerId && item.blob.productId === productId,
  );
};

export const findAvailableProduct = (
  parentProduct: ParentProduct | undefined,
  productId: string | undefined,
): [string | undefined, Product | undefined] => {
  let product: Product | undefined;
  let productGroupId: string | undefined;

  parentProduct?.childProductGroups.forEach((group) => {
    group.products.forEach((p) => {
      if (p.productId === productId) {
        product = p;
        productGroupId = group.productGroupId;
      }
    });
  });

  return [productGroupId, product];
};

export const checkIfAllProductsExist = (
  childProductIds: string[],
  parentProduct: ParentProduct | undefined,
  selectedProducts: Record<string, Product[]>,
) => {
  return childProductIds?.every((childProductId) => {
    const [productGroupId, product] = findAvailableProduct(
      parentProduct,
      childProductId,
    );
    if (!productGroupId || !product) {
      return false;
    }
    const existingProducts = selectedProducts[productGroupId] ?? [];
    return existingProducts.some(
      (existingProduct) => existingProduct.productId === product.productId,
    );
  });
};

export const mapProductGroups = (
  parentProduct: ParentProduct,
  existingPhoneNumberForm: PhoneNumberFormValues | null,
  selectedProducts: Record<string, Product[]>,
  prismicParentId: string,
  simCardReq?: Omit<SimValidateRo, 'orderId'>,
): ProductGroup[] => {
  const selectedProductParents: SelectedProduct[] = [
    {
      prismicParentId,
      productId: parentProduct.productId,
      pricingId: parentProduct.productPricing.pricingId,
      orderPortInReq: existingPhoneNumberForm ?? undefined,
      simCardReq,
      productPricing: parentProduct.productPricing,
      commercialName: parentProduct.commercialName,
    },
  ];
  const parentProductDto: ProductGroup = {
    productGroupId: parentProduct.productGroupId,
    selectedProducts: selectedProductParents,
  };

  const selectProductDtos: ProductGroup[] = Object.entries(selectedProducts)
    .filter(([, products]) => products.length > 0)
    .map(([productGroupId, products]) => ({
      productGroupId,
      selectedProducts: products.map((product) =>
        mapSelectedProduct(parentProduct.productId, product),
      ),
    }));

  return [parentProductDto, ...selectProductDtos];
};

export const mapSelectedProduct = (
  parentProductId: string,
  product: Product,
): SelectedProduct => ({
  productId: product.productId,
  pricingId: product.productPricing.pricingId,
  parentId: parentProductId,
  productPricing: product.productPricing,
  commercialName: product.commercialName,
});

const findCmsProduct = (cmsProducts: AvailableProduct[], productId: string) =>
  cmsProducts.find(
    (cmsProduct) => cmsProduct.product?.data?.product.id === productId,
  );

export const mapParentProducts = (
  parentProducts: ParentProductRo[] | undefined,
  availableProducts: AvailableProduct[],
): ParentProductRo[] | undefined =>
  parentProducts?.map((parentProduct) => {
    const product = findCmsProduct(
      availableProducts,
      parentProduct.blob.productId,
    );
    return {
      ...parentProduct,
      blob: {
        ...parentProduct.blob,
        childProductGroups: parentProduct.blob.childProductGroups.map(
          (childProductGroup) => ({
            ...childProductGroup,
            products: childProductGroup.products.map((product) => {
              const cmsProduct = findCmsProduct(
                availableProducts,
                product.productId,
              );
              return {
                ...product,
                ...cmsProduct?.product?.data,
                image: cmsProduct?.product?.data?.mainImage,
                showImage: cmsProduct?.product?.data?.showImage ?? true,
              };
            }),
          }),
        ),
        priceCases: parentProduct.blob.priceCases.map((priceCase) => ({
          ...priceCase,
          products: priceCase.products.map((product) => {
            const cmsProduct = findCmsProduct(
              availableProducts,
              product.productId,
            );
            return {
              ...product,
              ...cmsProduct?.product?.data,
              image: cmsProduct?.product?.data?.mainImage,
              showImage: cmsProduct?.product?.data?.showImage ?? true,
            };
          }),
        })),
      },
      ...product?.product?.data,
      image: product?.product?.data?.mainImage,
      showImage: product?.product?.data?.showImage ?? true,
    };
  });

export function updateMissingPurchaseFlowQueryParams(
  order: Order,
  query: PurchaseQueryParams,
  router: NextRouter,
) {
  const offerId = order?.offers?.[0]?.offerId;
  const productId = order?.offers?.[0]?.selectedProducts?.[0]?.productId;
  if (
    query.orderId &&
    (!query.offerId || !query.productId) &&
    productId &&
    offerId
  ) {
    router.replace({
      pathname: router.pathname,
      query: {
        ...router.query,
        offerId,
        productId,
      },
    });
  }
}

export const findSelectedProductInventoryTypes = (
  order: Order | null,
): SelectedProduct['inventoryTypes'] | undefined => {
  return order?.offers?.[0].selectedProducts?.[0].inventoryTypes?.filter(
    (inventory) =>
      inventory.inputType === 'USER' && inventory.dataType === 'SIM',
  );
};
