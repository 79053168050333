import { FC } from 'react';

import { DefaultProductList } from './default-slice/DefaultProductList';
import { DefaultProductListVariation, ProductListVariations } from './types';

export interface ProductListSliceProps {
  slice: ProductListVariations;
}

export const ProductListSlice: FC<ProductListSliceProps> = (props) => {
  const { slice } = props;

  return <DefaultProductList slice={slice as DefaultProductListVariation} />;
};
