import { withQueryParams } from '@lib/route/queryParams';
import { usePaginationQueryParams } from '@ui/pagination/usePaginationQueryParams';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

export const useParentProductsPagination = (productsCount: number) => {
  const { page, pageSize } = usePaginationQueryParams();
  const router = useRouter();
  const pageCount = useMemo(
    () => Math.ceil(productsCount / pageSize),
    [productsCount, pageSize],
  );

  const onPageChange = (page: number) => {
    router.push(
      withQueryParams(router.pathname, {
        ...router.query,
        page: page.toString(),
      }),
      undefined,
      {
        shallow: true,
      },
    );
  };

  return {
    page,
    pageSize,
    pageCount,
    onPageChange,
  };
};
