import { FC } from 'react';

import { Box, Skeleton, Typography } from '@mui/material';
import { DynamicImage } from '@ui/gallery/DynamicImage';

import { Tag } from '../types';
import { SxStyles } from '@lib/theme/sxTheme';
import { hasRichTextContent } from '@lib/slice-machine/richText';
import { CustomRichText } from '@ui/slice-machine/CustomRichText';

interface Props {
  tags?: Tag[];
  tagsTitle?: string;
}

export const TextLines: FC<Props> = ({ tags, tagsTitle }) => {
  if (!!tags?.length) {
    return (
      <Box mt={2}>
        {tagsTitle && <Typography>{tagsTitle}</Typography>}
        {tags?.map((tag, index) => (
          <Box key={index} sx={styles.getValue('tagWrapper')}>
            {tag?.tagImage && tag.tagImage?.url ? (
              <DynamicImage
                containerProps={{ sx: styles.getValue('imageWrapper') }}
                src={tag.tagImage?.url}
                alt={tag.tagImage?.alt ?? ''}
                fill
                objectFit="contain"
              />
            ) : (
              <Box sx={styles.getValue('imageWrapper')}>
                <Skeleton
                  variant="circular"
                  sx={styles.getValue('imageWrapper')}
                />
              </Box>
            )}
            {hasRichTextContent(tag.label) && (
              <CustomRichText
                render={tag.label}
                containerSx={styles.getValue('tagLabel')}
              />
            )}
          </Box>
        ))}
      </Box>
    );
  }

  return null;
};

const styles = new SxStyles({
  tagLabel: {
    fontWeight: 400,
    '& p': {
      fontSize: 14,
    },
  },
  tagWrapper: {
    display: 'flex',
    alignItems: 'center',
    pt: 1.5,
    justifyContent: 'flex-start',
    gap: 2,
  },
  imageWrapper: {
    width: 25,
    minWidth: 25,
    height: 25,
  },
});
