import * as React from 'react';
import { Box, Pagination as MuiPagination } from '@mui/material';

interface Props {
  pageCount?: number;
  currentPage?: number;
  onPageChange?: (page: number) => void;
}

export const Pagination: React.FC<Props> = ({
  pageCount = 1,
  currentPage = 1,
  onPageChange,
}) => {
  const [page, setPage] = React.useState(currentPage);
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, mb: 2 }}>
      <MuiPagination
        color="primary"
        count={pageCount}
        page={page}
        onChange={handleChange}
      />
    </Box>
  );

  function handleChange(_, value: number) {
    setPage(value);
    onPageChange?.(value);
  }
};
