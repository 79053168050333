import { ServiceCategory } from '@api/ocb-digital/subscription/types';

const getBaseLog = (x: number, y: number) => Math.log(y) / Math.log(x);

const convertDataQuantityToReadableFormat = (bytes: number): string => {
  if (!bytes) return '0 B';
  const divider = 1024;
  const sizeUnits = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const exponent = Math.floor(getBaseLog(divider, bytes));
  const readableSize = (bytes / Math.pow(divider, exponent)).toFixed(2);
  return `${parseFloat(readableSize)} ${sizeUnits[exponent]}`;
};

const convertVoiceQuantityToTimeFormat = (quantity: number): string => {
  const date = new Date(0);
  date.setSeconds(quantity);
  return date.toISOString().slice(11, 19);
};

export const convertCdrQuantityBasedOnServiceCategory = (
  serviceCategory: ServiceCategory | undefined,
  quantity: number,
) => {
  if (serviceCategory === ServiceCategory.DATA) {
    return convertDataQuantityToReadableFormat(quantity);
  } else if (serviceCategory === ServiceCategory.VOICE) {
    return convertVoiceQuantityToTimeFormat(quantity);
  } else return quantity || 0;
};

export const generateSourceId = (): string =>
  Date.now().toString(36) + Math.random().toString(36).substring(2);
