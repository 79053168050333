import { useRouter } from 'next/router';
import { DEFAULT_PAGE_SIZE } from './constants';

export const usePaginationQueryParams = () => {
  const { query } = useRouter();
  const { page, pageSize } = query;

  return {
    page: page ? parseInt(page as string, 10) : 1,
    pageSize: pageSize ? parseInt(pageSize as string, 10) : DEFAULT_PAGE_SIZE,
  };
};
