import { FC } from 'react';
import { useTranslation } from 'next-i18next';
import { ParentProductRo } from '@api/ocb-digital/offer/types';
import { ParentProductList } from '@templates/purchase/common/select-parent-product-step/parent-product-list/ParentProductList';
import { Pagination } from '@ui/pagination/Pagination';
import { NoDataPlaceholder } from '@ui/placeholders/NoDataPlaceholder';
import { CenteredLoader } from '@ui/loaders/CenteredLoader';
import { ErrorPlaceholder } from '@ui/placeholders/ErrorPlaceholder';
import { useParentProductSelection } from '@templates/purchase/common/useParentProductSelection';
import { APP_ROUTES } from '@lib/route/constants';
import { OrderType } from '@api/ocb-digital/order/types';
import { useParentProductsPagination } from '@templates/purchase/common/useParentProductsPagination';
import { usePathname } from 'next/navigation';

interface Props {
  products?: ParentProductRo[];
  productsCount?: number;
  offerCategoryId?: string;
  isLoading: boolean;
  isError: boolean;
}

export const ProductListContent: FC<Props> = ({
  products = [],
  productsCount = 1,
  offerCategoryId,
  isLoading,
  isError,
}) => {
  const { t } = useTranslation();
  const pathname = usePathname();
  const { onProductSelect } = useParentProductSelection({
    products,
    offerCategoryId,
    nextStepUrl: APP_ROUTES.PLANS.path,
    referenceUrl: pathname,
    orderType: OrderType.NEW_SUBSCRIPTION,
  });
  const { page, pageCount, onPageChange } =
    useParentProductsPagination(productsCount);

  if (isLoading) {
    return <CenteredLoader />;
  }

  if (isError) {
    return <ErrorPlaceholder />;
  }

  if (products && !products.length) {
    return <NoDataPlaceholder />;
  }

  return (
    <>
      <ParentProductList
        data={products}
        ctaProps={{
          text: t('select'),
          onClick: onProductSelect,
        }}
        isSpaced={false}
      />
      <Pagination
        pageCount={pageCount}
        currentPage={page}
        onPageChange={onPageChange}
      />
    </>
  );
};
