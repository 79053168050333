import { api } from '@api/index';
import {
  GetOfferParentProductsRo,
  ParentProductRo,
} from '@api/ocb-digital/offer/types';
import { getEnv } from '@lib/env/getEnv';
import { useTenantProps } from '@lib/tenants/TenantPropsContext';
import { mapParentProducts } from '@templates/purchase/common/helpers';
import { usePaginationQueryParams } from '@ui/pagination/usePaginationQueryParams';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { DEFAULT_LOCALE } from 'next-i18next.config';
import { useApiQuery } from '@lib/fetch/useApiQuery';
import { CACHE_KEYS } from '@lib/fetch/constants';
import { AvailableProductsRo } from '@api/prismic/components/types';
import { AvailableLocales } from '@lib/route/types';

interface Props {
  offerCategoryId?: string;
}

export const useParentProducts = ({
  offerCategoryId,
}: Props | undefined = {}) => {
  const { tenant } = useTenantProps();
  const { locale = DEFAULT_LOCALE } = useRouter();
  const env = getEnv();
  const tenantId = tenant?.config.setup.id[env.client.appEnv] ?? '';
  const { page, pageSize } = usePaginationQueryParams();
  const {
    data: availableProducts,
    isLoading: isAvailableProductsLoading,
    error: availableProductsError,
  } = useApiQuery(requestAvailableProducts);
  const {
    data: parentProducts,
    isLoading: isParentProductsLoading,
    error: parentProductsError,
  } = useApiQuery(requestParentProducts, {
    queryKey: CACHE_KEYS.parentProducts(
      offerCategoryId,
      String(page),
      String(pageSize),
    ),
  });
  const isLoading = isAvailableProductsLoading || isParentProductsLoading;
  const isError = availableProductsError || parentProductsError;
  const products: ParentProductRo[] | undefined = useMemo(
    () =>
      !isLoading
        ? mapParentProducts(parentProducts?.results, availableProducts)
        : [],
    [parentProducts?.results, availableProducts, isLoading],
  );

  return {
    products,
    isLoading,
    isError,
  };

  async function requestAvailableProducts() {
    let availableProducts: AvailableProductsRo | null = null;

    try {
      availableProducts = await api.prismic.components.getAvailableProducts(
        tenant,
        locale as AvailableLocales,
      );
    } catch (error) {
      throw error;
    }

    return availableProducts?.data?.availableProducts || [];
  }

  async function requestParentProducts() {
    let products: GetOfferParentProductsRo | null = null;

    try {
      products = await api.ocbDigital.offer.getOfferProducts(tenant, {
        tenantId,
        page,
        pageSize,
        ...(offerCategoryId && { offerCategoryId }),
      });
    } catch (error) {
      throw error;
    }

    return products;
  }
};
