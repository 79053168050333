import { FC, PropsWithChildren } from 'react';

import { useTenantProps } from '@lib/tenants/TenantPropsContext';
import { SxTheme } from '@lib/theme/types';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';

interface Props {
  containerSx?: SxTheme;
  sx?: SxTheme;
}

export const OfferCardTitle: FC<PropsWithChildren<Props>> = ({
  children,
  containerSx,
  sx,
}) => {
  const {
    tenant: { theme },
  } = useTenantProps();

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      py={2}
      px={1}
      sx={containerSx}
      data-testid="main-product-title"
    >
      <Typography
        display="block"
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
        fontWeight="fontWeightMedium"
        color={theme.specifics?.offerCard?.valueColor ?? 'primary'}
        fontSize="h5.fontSize"
        sx={sx}
      >
        {children}
      </Typography>
    </Box>
  );
};
