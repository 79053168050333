import { FC } from 'react';

import { ParentProductRo } from '@api/ocb-digital/offer/types';
import { Grid } from '@mui/material';
import { ContentSpacer } from '@templates/common/layout/ContentSpacer';

import { ParentProductCard } from './parent-product-card/ParentProductCard';
import { CtaProps } from './types';

interface Props {
  data: ParentProductRo[];
  isSpaced: boolean;
  ctaProps?: CtaProps;
}

export const ParentProductList: FC<Props> = ({ data, isSpaced, ctaProps }) => {
  const children = (
    <Grid
      container
      justifyContent="center"
      spacing={2}
      data-testid="main-product-step"
    >
      {data.map((item, index) => (
        <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
          <ParentProductCard item={item} ctaProps={ctaProps} />
        </Grid>
      ))}
    </Grid>
  );

  return isSpaced ? <ContentSpacer>{children}</ContentSpacer> : children;
};
