import { FC } from 'react';

import { ProductPricing } from '@lib/offers/types';
import { SxTheme } from '@lib/theme/types';
import { Stack } from '@mui/material';

import { OneTimeFee } from './OneTimeFee';
import { RecurringFee } from './RecurringFee';

interface Props {
  pricing?: ProductPricing;
  fontSize?: number | string;
  sx?: SxTheme;
}

export const Price: FC<Props> = ({ pricing, fontSize = '24px', sx }) => {
  return (
    <Stack
      sx={{ justifyContent: 'flex-end', ...sx }}
      spacing={1}
      direction="row"
    >
      {typeof pricing?.recurringFee?.price === 'number' ? (
        <RecurringFee pricing={pricing} fontSize={fontSize} />
      ) : (
        <OneTimeFee pricing={pricing} fontSize={fontSize} />
      )}
    </Stack>
  );
};
