import { FC } from 'react';
import { useTranslation } from 'next-i18next';

import { Typography } from '@mui/material';

interface Props {
  subscriptionTerm?: number;
}

export const SubscriptionTerm: FC<Props> = ({ subscriptionTerm }) => {
  const { t } = useTranslation();
  if (!!subscriptionTerm) {
    return (
      <Typography sx={{ fontSize: '12px', lineHeight: '14px' }}>
        {t('common:prepaidPackages.term', {
          term: subscriptionTerm,
        })}
      </Typography>
    );
  }

  return null;
};
