import { FC } from 'react';

import { Image } from '@lib/slice-machine/types';
import { Box } from '@mui/material';
import { DynamicImage } from '@ui/gallery/DynamicImage';

interface Props {
  image?: Image;
}

export const ImageContainer: FC<Props> = ({ image }) => {
  if (!image?.url) return null;
  return (
    <Box
      sx={{
        display: 'flex',
        position: 'relative',
        flexShrink: 0,
        width: '100%',
        height: 145,
        borderRadius: 'inherit',
      }}
    >
      {image?.url && (
        <DynamicImage
          src={image.url}
          alt={image.alt ?? ''}
          objectFit="scale-down"
          fill
        />
      )}
    </Box>
  );
};
